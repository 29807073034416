import { Card, Col, Form, InputNumber, Row, Select, Space, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetInvoice } from "../hooks/use-get-invoice";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormInvoice } from "../hooks/use-form-invoice";
import {
  InvoicePayloadType,
  InvoiceFormData,
  InvoiceProductFormData,
} from "./interface";
import {
  ApiSelectValue,
  convertToSelectValue,
} from "../../../components/select/function";
import CustomerRepository from "../../../repositories/customer-repository";
import ProjectRepository from "../../../repositories/project-repository";
import VuiDatePicker from "../../../components/vui-date-picker";
import moment from "moment";
import { Customer } from "../../../models/customer";
import { User } from "../../../models/user";
import { DeleteOutlined } from "@ant-design/icons";
import ProductRepository from "../../../repositories/product-repository";
import VuiNumberFormat from "../../../components/number-format";
import { inputNumberThousandSeparator } from "../../../utils/helpers";
import ConstantRepository from "../../../repositories/constant-repository";
import UnitRepository from "../../../repositories/unit-repository";
import { AxiosResponse } from "axios";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { ValueType } from "../../../components/select/interface";

const invoiceProductDefaultValue = {
  product_id: null,
  description: "",
  price: 0,
  unit_id: null,
  quantity: 0,
};

const InvoiceFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const title = id
    ? t("pages.invoice.edit.title")
    : t("pages.invoice.request.title");
  const { loadDetailData, isOnFetchingDetailData, detailData } =
    useGetInvoice();
  const { submitLoading, onSubmit } = useFormInvoice();

  const [form] = Form.useForm();

  const selectedCustomer = Form.useWatch("customer_id", form);

  const selectedSendType = Form.useWatch("sent_type_id", form);

  const selectedTransactionDate = Form.useWatch("date", form);

  const onFinish = useCallback(
    async (data: InvoiceFormData) => {
      const normalizeData: InvoicePayloadType = {
        customer_id: data.customer_id.value as number,
        project_id: data.project_id.value as number,
        date: moment(data.date).format("YYYY-MM-DD"),
        tempo: data.tempo,
        is_ppn: data.is_ppn,
        billing_address: data.billing_address,
        invoice_products: data.invoice_products
          .filter((item) => !!item.product_id)
          .map((item) => {
            return {
              ...item,
              product_id: item.product_id.value as number,
              unit_id: item.unit_id.value as number,
            };
          }),
        sent_type_id: data.sent_type_id.value as number,
        sent_to: data.sent_to,
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const onCustomerChange = (selected: ValueType) => {
    CustomerRepository.show(selected.value as string).then(
      (response: AxiosResponse<IResource<Customer>>) => {
        const data = response.data.data;
        form.setFieldValue("billing_address", data.billing_address);
        form.setFieldValue("sent_to", data.finance_emails);
      }
    );

    form.resetFields(["project_id", "project_manager_id"]);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await loadDetailData(id, {
          with: [
            "customer",
            "projectManager",
            "project",
            "sentType",
            "invoiceProducts.product",
            "invoiceProducts.unit",
            "invoiceEmails",
          ],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && detailData) {
      const invoiceProducts =
        detailData.invoice_products?.map((item) => {
          return {
            ...item,
            product_id: convertToSelectValue(
              item.product || ({} as ApiSelectValue)
            ),
            unit_id: convertToSelectValue(item.unit || ({} as ApiSelectValue)),
          };
        }) || [];
      form.setFieldsValue({
        ...detailData,
        date: moment(detailData.date),
        customer_id: convertToSelectValue(
          detailData.customer || ({} as Customer)
        ),
        project_manager_id: convertToSelectValue(
          detailData.project_manager || ({} as User)
        ),
        sent_type_id: {
          label: detailData?.sent_type?.label,
          value: detailData?.sent_type?.id,
        },
        project_id: {
          label: detailData?.project?.code,
          value: detailData?.project?.id,
        },
        sent_date: moment(detailData.sent_date),
        sent_to: detailData.invoice_emails?.map((item) => item.email),
        invoice_products: [
          ...invoiceProducts,
          {
            ...invoiceProductDefaultValue,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  return (
    <>
      <VuiPageTitle
        title={title}
        onBackLink={id ? `/invoice/${id}` : "/invoice"}
      />

      <Spin size="large" spinning={isOnFetchingDetailData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={8} xs={24}>
              <Card title={t("common.text.generalInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="customer_id"
                      label={t("common.form.client.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.client.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={CustomerRepository}
                        placeholder={t("common.form.client.placeholder")}
                        onChange={onCustomerChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="billing_address"
                      label={t("common.form.billingAddress.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.billingAddress.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t(
                          "common.form.billingAddress.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md={16} xs={24}>
              <Card title={t("common.text.projectInformation")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      name="project_id"
                      label={t("common.form.idProject.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.idProject.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ProjectRepository}
                        placeholder={t("common.form.idProject.placeholder")}
                        labelKey="code"
                        repositoryParams={{
                          customer: selectedCustomer?.value,
                        }}
                        onChange={() => {
                          form.resetFields(["project_manager_id"]);
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="sent_to"
                      label={t("common.form.sendTo.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.sendTo.label"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder={t("common.form.sendTo.placeholder")}
                        options={[]}
                        open={false}
                        size="large"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="sent_type_id"
                      label={t("common.form.sendType.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.sendType.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect
                        repository={ConstantRepository}
                        repositoryParams={{
                          for: "invoice_sent_type",
                        }}
                        labelKey="label"
                        placeholder={t("common.form.sendType.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="date"
                      label={t("common.form.transactionDate.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.transactionDate.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiDatePicker
                        onChange={() => form.resetFields(["sent_date"])}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item
                      name="tempo"
                      label={t("common.form.top.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.top.label"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber
                        size="large"
                        placeholder={t("common.form.top.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  {selectedSendType?.label === "Auto" && (
                    <Col xs={24} md={8}>
                      <Form.Item
                        name="sent_date"
                        label={t("common.form.sendDate.label")}
                        rules={[
                          {
                            required: true,
                            message: t("validation.required", {
                              item: t("common.form.sendDate.label"),
                            }),
                          },
                        ]}
                      >
                        <VuiDatePicker
                          format={"DD MMM YYYY, HH:mm"}
                          disabledDate={(current) => {
                            let transactionDate = moment(
                              selectedTransactionDate
                            ).format("YYYY-MM-DD");
                            return (
                              current &&
                              current <
                                moment(transactionDate, "YYYY-MM-DD").add(
                                  2,
                                  "days"
                                )
                            );
                          }}
                          showTime={{ format: "HH:mm" }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <Form.List
                name={["invoice_products"]}
                initialValue={[
                  {
                    ...invoiceProductDefaultValue,
                  },
                ]}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error(
                            t("validation.required", {
                              item: t("common.text.gift"),
                            })
                          )
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Space
                    style={{
                      width: "100%",
                    }}
                    direction="vertical"
                    size={15}
                  >
                    <Form.ErrorList errors={errors} />
                    <div className="ant-table-wrapper">
                      <div className="ant-table ant-table-small">
                        <div className="ant-table-container">
                          <div
                            className="ant-table-content"
                            style={{ overflowX: "auto" }}
                          >
                            <table style={{ tableLayout: "auto" }}>
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">Product</th>
                                  <th className="ant-table-cell">
                                    Description
                                  </th>
                                  <th className="ant-table-cell">Qty</th>
                                  <th className="ant-table-cell">Units</th>
                                  <th className="ant-table-cell">
                                    Unit Price (Rp)
                                  </th>
                                  <th className="ant-table-cell">
                                    Amount (Rp)
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                {fields.map(({ key, name, ...restField }) => {
                                  return (
                                    <tr
                                      key={key}
                                      className="ant-table-row ant-table-row-level-0"
                                    >
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          style={{ minWidth: "130px" }}
                                          {...restField}
                                          name={[name, "product_id"]}
                                        >
                                          <VuiSelect
                                            onChange={() => {
                                              if (!fields[name + 1]) {
                                                add({
                                                  ...invoiceProductDefaultValue,
                                                });
                                              }

                                              form.setFieldValue(
                                                [
                                                  `invoice_products`,
                                                  key,
                                                  "unit_id",
                                                ],
                                                null
                                              );
                                            }}
                                            repository={ProductRepository}
                                            placeholder={t(
                                              "common.form.product.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>

                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{ minWidth: "200px" }}
                                          name={[name, "description"]}
                                        >
                                          <Input
                                            size="large"
                                            placeholder={t(
                                              "common.form.description.placeholder"
                                            )}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{
                                            width: "70px",
                                            flex: 1,
                                          }}
                                          name={[name, "quantity"]}
                                        >
                                          <InputNumber
                                            placeholder={t(
                                              "common.form.qty.placeholder"
                                            )}
                                            size="large"
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item shouldUpdate className="mb0">
                                          {() => {
                                            const product = form.getFieldValue([
                                              `invoice_products`,
                                              key,
                                              "product_id",
                                            ]);
                                            return (
                                              <Form.Item
                                                className="mb0"
                                                {...restField}
                                                name={[name, "unit_id"]}
                                                style={{
                                                  minWidth: "100px",
                                                }}
                                              >
                                                <VuiSelect
                                                  repository={UnitRepository}
                                                  placeholder={t(
                                                    "common.form.unit.placeholder"
                                                  )}
                                                  repositoryParams={{
                                                    product: product?.value,
                                                  }}
                                                  disabled={!product?.value}
                                                />
                                              </Form.Item>
                                            );
                                          }}
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <Form.Item
                                          className="mb0"
                                          {...restField}
                                          style={{
                                            minWidth: "150px",
                                            flex: 1,
                                          }}
                                          name={[name, "price"]}
                                        >
                                          <InputNumber
                                            placeholder={t(
                                              "common.form.unitPrice.placeholder"
                                            )}
                                            formatter={
                                              inputNumberThousandSeparator.formatter
                                            }
                                            parser={
                                              inputNumberThousandSeparator.parser
                                            }
                                            size="large"
                                          />
                                        </Form.Item>
                                      </td>
                                      <td className="ant-table-cell">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            columnGap: 10,
                                          }}
                                        >
                                          <Form.Item
                                            shouldUpdate
                                            className="mb0"
                                            style={{
                                              minWidth: "100px",
                                              flex: 1,
                                            }}
                                          >
                                            {() => {
                                              const qty = form.getFieldValue([
                                                `invoice_products`,
                                                key,
                                                "quantity",
                                              ]);
                                              const price = form.getFieldValue([
                                                `invoice_products`,
                                                key,
                                                "price",
                                              ]);

                                              return (
                                                <VuiNumberFormat
                                                  className={
                                                    "display-flex justify-content-between"
                                                  }
                                                  value={qty * price}
                                                />
                                              );
                                            }}
                                          </Form.Item>
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => remove(name)}
                                          >
                                            <DeleteOutlined
                                              style={{ fontSize: 18 }}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Space>
                )}
              </Form.List>
            </Col>

            <Col xs={24}>
              <div className="text-align-right mt24">
                <Form.Item shouldUpdate>
                  {() => {
                    const invoiceProducts =
                      form.getFieldValue("invoice_products");
                    const subtotal = invoiceProducts
                      .map((item: InvoiceProductFormData) => {
                        const price = item?.price || 0;
                        const qty = item?.quantity || 0;
                        return Number(item?.product_id ? price * qty : 0);
                      })
                      .reduce((total: number, num: number) => total + num);

                    const total = subtotal;

                    return (
                      <div>
                        <Row justify="end">
                          <Col
                            xs={24}
                            lg={8}
                            style={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <table className="table-summary type-one">
                              <tbody>
                                <tr>
                                  <td style={{ minWidth: 100 }}>
                                    {t("common.text.subtotal")}
                                  </td>
                                  <td style={{ textAlign: "end" }}>
                                    <VuiNumberFormat
                                      value={subtotal}
                                      prefix={"Rp "}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>{t("common.text.total")}</b>
                                  </td>
                                  <td>
                                    <b>
                                      <VuiNumberFormat
                                        value={total}
                                        prefix={"Rp "}
                                      />
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Form.Item>
              </div>
            </Col>

            <Col xs={24}>
              <VuiFormActionButton
                cancelBtnLink={id ? `/invoice/${id}` : "/invoice"}
                isLoading={submitLoading}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default InvoiceFormModule;
